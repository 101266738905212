export const isSmScreen = () => window.innerWidth <= 640;
export const isLgScreen = () => window.innerWidth >= 1024;

export const scrollToElementById = (id: string) => {
  window.location.href = `/#${id}`;
  // if (id.length !== 0) {
  // 	const destination = document.querySelector(`#${id}`);
  // 	destination?.scrollIntoView({ behavior: "smooth" });
  // } else {
  // 	const destination = document.querySelector("body");
  // 	destination?.scrollIntoView({ behavior: "smooth" });
  // }
};
