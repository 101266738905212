import { MutableRefObject, useEffect } from "react";

const useOutsideAlerter = (ref: MutableRefObject<any>, callback: () => void) => {
	useEffect(() => {
		const handleClickOutside = (event: any) => {
			if (ref.current && !ref.current.contains(event.target)) {
				callback();
			}
		};

		document.addEventListener("mousedown", handleClickOutside);
		return () => {
			document.removeEventListener("mousedown", handleClickOutside);
		};
	}, [callback, ref]);
};

export default useOutsideAlerter;
