import InstagramIcon from "@mui/icons-material/Instagram";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import EmailIcon from "@mui/icons-material/Email";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";
// import ContactImage from "../images/contact.svg";

interface ContactProps {}

const Contact: React.FC<ContactProps> = () => {
  return (
    <div
      id="contact"
      className="h-fit items-center flex mx-auto _container lg:px-0 py-16 font-medium pb-20 flex-col justify-center"
      style={{ color: "#181b31", backgroundColor: "#ededed" }}
    >
      <div className="w-full">
        <div className="flex lg:flex-row flex-col">
          <div className="flex-grow lg:w-1/2 lg:ml-20 px-10">
            <div className="flex flex-col">
              <span className="lg:text-6xl text-5xl font-bold">Get in touch</span>
            </div>
            <div className="w-full font-gi">
              <div className="text-base leading-loose font-normal whitespace-pre-wrap">
                <div>
                  <div className="lg:w-3/4 mt-10">You can find me on instagram or you can message me on Whatsapp or via Email</div>
                  <div className="flex flex-row gap-5 my-5">
                    <a href="tel:+919833883165">
                      <LocalPhoneIcon className="cursor-pointer" />
                    </a>
                    <a href="https://www.instagram.com/happy.cubs.dr.ameeshi">
                      <InstagramIcon className="cursor-pointer" />
                    </a>
                    <a href="mailto:drameeshi@gmail.com">
                      <EmailIcon className="cursor-pointer" />
                    </a>
                    <a href="https://wa.me/+919833883165">
                      <WhatsAppIcon className="cursor-pointer" />
                    </a>
                  </div>
                  <div className="flex flex-col">
                    <span className="text-xl font-semibold py-2">Address</span>
                    <ul className="list-disc">
                      <li>
                        <div className="flex flex-col mb-5">
                          <span className="font-semibold text-lg">Advanced Multispeciality Hospital</span>
                          <span>
                            <span className="font-semibold">Address:</span> CMPH Medical College Campus, Natakar Gadkari Marg, Gulmohar Rd, opp. Amar Juice
                            Centre, Irla, Vile Parle West, Mumbai, 400056
                          </span>
                          <span>
                            <span className="font-semibold">For appointment</span> call 9833883165
                          </span>
                          <span>
                            <span className="font-semibold">Timings</span> Monday to Saturday 5 to 7 pm
                          </span>
                        </div>
                      </li>
                      <li>
                        <div className="flex flex-col mb-5">
                          <span className="font-semibold text-lg">Healthcare diagnostics</span>
                          <span>
                            <span className="font-semibold">Address:</span> 1st Floor , Patel House, Rang Bhawan Bldg , MG Cross Rd, above PODDAR KIDS SCHOOL,
                            Navpada, Vile Parle East, Vile Parle, Mumbai 400057
                          </span>
                          <span>
                            <span className="font-semibold">Timings:</span> m/w/f 11am to 1 pm
                          </span>
                          <span>
                            <span className="font-semibold">For appointments:</span> 081042 72979/9833883165
                          </span>
                        </div>
                      </li>
                      <li>
                        <div className="flex flex-col mb-5">
                          <span className="font-semibold text-lg">Irla nursing home</span>
                          <span>
                            <span className="font-semibold">Address:</span> Majithia Apartments, S.V. Road, Irla, Vile Parle ( West ), Mumbai 400056.
                          </span>
                          <span>
                            <span className="font-semibold">Timings:</span> by appointment only
                          </span>
                          <span>
                            <span className="font-semibold">For appointments</span> 8591719078 /9833883165
                          </span>
                        </div>
                      </li>
                      <li>
                        <div className="flex flex-col mb-5">
                          <span className="font-semibold text-lg">Arogya Jain Medical Center</span>
                          <span>
                            <span className="font-semibold">Address:</span> Gala No. 3, Sikhar Hubtown, Parsi Panchayat Rd, opp. Sona Udyog Industrial Estate,
                            Jijamata Colony, Andheri East, Mumbai 400069
                          </span>
                          <span>
                            <span className="font-semibold">Timings:</span> on Saturday by prior appointment
                          </span>
                          <span>
                            <span className="font-semibold">For appointments:</span> 7221975975 / 9833883165
                          </span>
                        </div>
                      </li>
                    </ul>
                    <br />
                    <span className="text-xl font-semibold py-2">Visiting at</span>
                    <ul className="list-disc">
                      <li>
                        <strong className="font-semibold">Advanced Multispeciality Hospital</strong>
                      </li>
                      <li>
                        <strong className="font-semibold">Irla nursing home </strong>
                      </li>
                      <li>
                        <strong className="font-semibold">Criticare hospital (Juhu)</strong>
                      </li>
                      <li>
                        <strong className="font-semibold">Nakshatra hospital</strong>
                      </li>
                      <li>
                        <strong className="font-semibold">Kripa hospital</strong>
                      </li>
                      <li>
                        <strong className="font-semibold">Mangal Nursing home</strong>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="flex justify-center items-center lg:w-1/2 w-full lg:my-0 mr-20 px-10 my-10 lg:h-auto h-[300px] max-h-[800px]">
            <iframe
              className="w-full h-full rounded-3xl"
              title="map"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3769.95543478586!2d72.8376495!3d19.1096109!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7c9c670d495ef%3A0xc02d140ac68e1167!2sAdvanced%20Multispeciality%20Hospital%2C%20Navpada%2C%20Tata%20Colony%2C%20Vile%20Parle%20West%2C%20Mumbai%2C%20Maharashtra%20400056!5e0!3m2!1sen!2sin!4v1715690033144!5m2!1sen!2sin"
              // width="800"
              // height="600"
              style={{ border: "0" }}
              loading="lazy"
            ></iframe>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contact;
