import Rating from "@mui/material/Rating";

interface CardsProps {
  rating: number;
  name: string;
  description: string;
  subtitle: string;
}

const Cards: React.FC<CardsProps> = ({ rating, description, name, subtitle }) => {
  return (
    <div className="flex flex-col min-h-[300px] w-80 p-10 shadow-xl rounded-2xl gap-4">
      <div className="flex flex-col">
        <div>
          <div className="text-xl font-semibold">{name}</div>
          <div>{subtitle}</div>
        </div>
        <div>
          <Rating name="half-rating" defaultValue={rating} precision={0.1} readOnly />
        </div>
      </div>
      <div className="w-60">{description}</div>
    </div>
  );
};

export default Cards;
