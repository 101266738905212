// import HomeImage from "../images/home.jpg";
import Logo from "../images/logo-icon.png";
// import HomeImage from "../images/home1.png";
import { scrollToElementById } from "../utils/BrowserUtils";
import Button from "./Button";

interface HomeProps {}

const Home: React.FC<HomeProps> = () => {
  return (
    <div className="flex items-center justify-center xl:h-screen h-fit sticky">
      <div className="flex items-center gap-2 xl:h-full w-full flex-col-reverse xl:flex-row">
        <div className="xl:w-1/2 flex flex-col justify-center items-center bg-white pt-4 xl:pt-0">
          <div className="xl:w-2/3 flex flex-col gap-5 px-10 xl:px-0 items-center xl:items-start">
            <div className="w-1/5 hidden xl:block">
              <img src={Logo} alt="" />
            </div>
            <div className="text-5xl xl:text-7xl font-bold text-center xl:text-left">Dr. Ameeshi Doshi</div>
            <div className="text-lg xl:text-xl text-center xl:text-left">Pediatrician, Lactation Consultant, Allergy & Asthma Specialist</div>
            <div className="text-base leading-loose font-normal whitespace-pre-wrap text-center xl:text-left md:w-1/2 xl:w-full" style={{ color: "#6d6d6d" }}>
              Welcome to Happy Cubs, where we are dedicated to providing exceptional and comprehensive care for newborns and the pediatric population. Led by
              the esteemed Dr. Ameeshi Doshi, we offer a warm and welcoming environment where children's health and well-being are our top priorities.
            </div>
            <div className="xl:mt-5">
              <Button
                onClick={() => {
                  scrollToElementById("services");
                }}
              >
                Services
              </Button>
            </div>
          </div>
        </div>
        <div className="px-10 xl:w-1/2 xl:h-full flex items-center justify-center h-fill xl:pt-0 pt-36">
          <img
            className="w-full"
            // src={HomeImage}
            src="https://img.freepik.com/vetores-premium/pediatra-examina-criancas-e-bebes-para-desenvolvimento-e-tratamento-em-desenhos-animados-desenhados-a-mao_2175-10570.jpg"
            alt=""
          />
        </div>
      </div>
    </div>
    // <div
    //   className="xl:h-screen h-fit items-center flex mx-auto _container xl:px-0 py-16 font-medium pb-20 flex-col justify-center"
    //   style={{ color: "#181b31" }}
    // >
    //   <div>
    //     <div className="flex xl:flex-row flex-col">
    //       <div className="flex-grow xl:w-1/2 xl:ml-20">
    //         <div className="py-10">
    //           <span className="xl:text-6xl text-5xl font-bold font-gi">Dr. Ameeshi Doshi</span>
    //         </div>
    //         <div className="xl:w-4/5 font-gi">
    //           <span className="text-base leading-loose font-normal whitespace-pre-wrap">
    //             <div>Pediatrician, Lactation Consultant, Allergy & Asthma Specialist</div>
    //           </span>
    //         </div>
    //       </div>
    //       <div className="flex justify-center items-start flex-grow xl:w-2/3 xl:px-10 mt-16 xl:-mx-20">
    //         <img
    //           src="https://img.freepik.com/vetores-premium/pediatra-examina-criancas-e-bebes-para-desenvolvimento-e-tratamento-em-desenhos-animados-desenhados-a-mao_2175-10570.jpg"
    //           alt=""
    //         />
    //       </div>
    //     </div>
    //   </div>
    // </div>
  );
};

export default Home;
