import { useEffect, useRef, useState } from "react";
import Card from "./Card";
// import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIosNew";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import useInterval from "../hooks/UseInterval";

interface ReviewsProps {}

const Reviews: React.FC<ReviewsProps> = () => {
  const reviewsRef = useRef<HTMLDivElement>(null);
  const [currentIndex, setCurrentIndex] = useState<number>(0);
  const NumberOfReviews = 4;

  useInterval(() => {
    setCurrentIndex(prevCurrentIndex => (prevCurrentIndex + 1) % NumberOfReviews);
  }, 4000);

  useEffect(() => {
    const reviews = reviewsRef.current;

    if (reviews) {
      reviews.scrollTo({
        left: (currentIndex * reviews.scrollWidth) / NumberOfReviews,
        behavior: "smooth",
      });
    }
  }, [currentIndex]);

  return (
    <div id="reviews" className="flex flex-col items-center justify-center py-20">
      <div className="flex flex-col items-center">
        <span className="text-5xl font-semibold mb-8 w-fit text-center">Patient Testimonials</span>
        <div className="w-20 h-4" style={{ backgroundColor: "#ffe8ec" }}></div>
      </div>
      <div className="flex py-10 xl:py-20 gap-10 overflow-y-hidden overflow-x-hidden w-full px-10 items-center xl:justify-center" ref={reviewsRef}>
        <Card description="Very patient and understanding doctor. Very happy with my child's treatment." name="Harshil shah" rating={5} subtitle="Father" />

        <Card description="Doctor is always available. Even via phone. Always helps clear all my doubts." name="Priyanka tambe" rating={4} subtitle="Student" />

        <Card
          description="Helped me greatly to get back to work and continue breast feeding my little one."
          name="Kinjal Parikh"
          rating={5}
          subtitle="Mother"
        />

        <Card
          description="My son kept falling ill multiple times. Doctor helped correctly diagnose him with allergic rhinitis.
          Best thing about her is no unnecessary antibiotics!"
          name="Tejal Mehta"
          rating={5}
          subtitle="Mother"
        />
      </div>
      <div className="flex gap-5 mt-5">
        <div
          className="p-3 rounded-full border"
          onClick={() => setCurrentIndex(prevCurrentIndex => (prevCurrentIndex + NumberOfReviews - 1) % NumberOfReviews)}
        >
          <ArrowBackIosIcon />
        </div>
        <div className="p-3 rounded-full border" onClick={() => setCurrentIndex(prevCurrentIndex => (prevCurrentIndex + 1) % NumberOfReviews)}>
          <ArrowForwardIosIcon />
        </div>
      </div>
    </div>
  );
};

export default Reviews;
