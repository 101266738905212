// import AboutImage from "../images/image.png";
import AboutImage from "../images/about.jpg";
interface ServicesProps {}

const Services: React.FC<ServicesProps> = () => {
  return (
    <div className="py-40 pb-10 xl:pb-40 flex-col items-center justify-center flex" id="services">
      <div className="w-full flex items-center justify-center lg:text-6xl text-5xl font-bold">Services</div>
      <div className="w-20 h-4 mt-8" style={{ backgroundColor: "#ffe8ec" }}></div>
      <div
        className="h-fit items-center flex mx-auto _container lg:px-0 py-16 font-medium pb-4 pt-0 xl:pt-16 flex-col justify-center"
        style={{ color: "#181b31" }}
      >
        <div>
          <div className="flex lg:flex-row flex-col">
            <div className="flex justify-center items-center flex-grow lg:w-2/3 lg:px-10 mt-16 lg:-mx-20">
              <img src={AboutImage} alt="About" className="w-full" style={{ filter: "brightness(2) invert(1) grayscale(1)" }} />
            </div>
            <div className="flex-grow lg:w-1/2 lg:ml-20 px-10">
              <div className="py-10">
                <span className="lg:text-5xl text-4xl font-semibold font-gi">Pediatric Services</span>
              </div>
              <div className="lg:w-4/5 font-gi">
                <span className="text-base leading-loose font-normal whitespace-pre-wrap">
                  <div>
                    <ul className="list-disc">
                      <li>
                        <strong>Pediatric Consultation:</strong> Comprehensive medical consultations for children, addressing a wide range of health concerns.
                      </li>
                      <li>
                        <strong>Fever Clinic:</strong> Specialized care for children with fever, ensuring prompt diagnosis and treatment.
                      </li>
                      <li>
                        <strong>Neonatal Assessment and Care:</strong> Expert care and monitoring for newborns to ensure a healthy start to life.
                      </li>
                      <li>
                        <strong>Early Childhood Developmental Assessment:</strong> Evaluations to track and support your child's developmental milestones.
                      </li>
                      <li>
                        <strong>Pediatric Nutritional Assessment and Counseling:</strong> Guidance on optimal nutrition to promote healthy growth and
                        development.
                      </li>
                      <li>
                        <strong>Vaccination Services:</strong> Administering vaccinations to protect children from various infectious diseases.
                      </li>
                    </ul>
                  </div>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="h-fit items-center flex mx-auto _container lg:px-0 py-16 font-medium xl:pb-20 pb-0 flex-col justify-center pt-0 xl:pt-16"
        style={{ color: "#181b31" }}
      >
        <div>
          <div className="flex lg:flex-row flex-col">
            <div className="flex-grow lg:w-1/2 lg:ml-20 px-10">
              <div className="py-10">
                <span className="lg:text-5xl text-4xl font-semibold font-gi">Lactational Consultant Services</span>
              </div>
              <div className="lg:w-4/5 font-gi">
                <span className="text-base leading-loose font-normal whitespace-pre-wrap">
                  <div>
                    Dr. Ameeshi Doshi is an internationally certified Lactational Consultant who has successfully guided many mothers through their lactation
                    journey. She offers invaluable support to new mothers, helping them navigate breastfeeding challenges and continue lactation even after
                    returning to work.
                  </div>
                </span>
              </div>
            </div>
            <div className="bg-black h-full w-0.5 self-strech hidden lg:block"></div>
            <div className="flex-grow lg:w-1/2 px-10 lg:pl-32 border-l">
              <div className="py-10">
                <span className="lg:text-5xl text-4xl font-semibold font-gi">Allergy and Asthma Clinic</span>
              </div>
              <div className="lg:w-4/5 font-gi">
                <span className="text-base leading-loose font-normal whitespace-pre-wrap">
                  <div>
                    With specialized training in Allergy and Asthma management from B.V.P Institute, Bangalore, Dr. Ameeshi Doshi is dedicated to helping
                    patients manage chronic ailments such as Allergic Rhinitis, Atopic Dermatitis, and Asthma. Our clinic provides services including Allergic
                    Skin Testing and Allergic Immunotherapy, among others, to ensure effective management and relief from allergic conditions.
                  </div>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Services;
