import Contact from "./components/Contact";
import Home from "./components/Home";
import Navbar from "./components/Navbar";
import Reviews from "./components/Reviews";
import Services from "./components/Services";

interface AppProps {}

const App: React.FC<AppProps> = () => {
  return (
    <div className="h-full">
      <Navbar />
      <Home />
      <Services />
      <Reviews />
      <Contact />
    </div>
  );
};

export default App;
