// interface NavbarProps {}

// const Navbar: React.FC<NavbarProps> = () => {
//   return (
//     <nav>
//       <div className="flex items-center justify-between p-4 fixed w-full shadow px-16 z-50 bg-white">
//         <div>
//           <img alt="" src={logo} className="h-12" />
//         </div>
//         <div className="flex gap-4 font-semibold">
//           <div>Home</div>
//           <div>Services</div>
//           <div>Contact</div>
//         </div>
//       </div>
//     </nav>
//   );
// };

// export default Navbar;

import { useEffect, useRef, useState } from "react";

import MenuIcon from "@mui/icons-material/Menu";
import CloseIcon from "@mui/icons-material/Close";
import useOutsideAlerter from "../hooks/UseOutsideAlerter";
import { scrollToElementById } from "../utils/BrowserUtils";
import logo from "../images/logo.png";

interface NavbarProps {
  noSticky?: boolean;
  defaultColor?: string;
}

const Navbar: React.FC<NavbarProps> = ({ noSticky, defaultColor = "black" }) => {
  const navbarRef = useRef<HTMLDivElement>(null);
  const [menuIsOpen, setMenuOpen] = useState<boolean>(false);

  useOutsideAlerter(navbarRef, () => {
    setMenuOpen(false);
  });

  useEffect(() => {
    let added = false;
    window.addEventListener("scroll", () => {
      if (window.scrollY > 0 && !added) {
        navbarRef.current?.classList.add("bg-white");
        navbarRef.current?.classList.add("text-black");
        navbarRef.current?.classList.remove(`text-${defaultColor}`);
        navbarRef.current?.classList.add("shadow");
        added = true;
      } else if (window.scrollY === 0) {
        added = false;
        // navbarRef.current?.classList.remove("bg-white");
        navbarRef.current?.classList.remove("text-black");
        navbarRef.current?.classList.add(`text-${defaultColor}`);
        // navbarRef.current?.classList.remove("shadow");
      }
    });
  }, [setMenuOpen, defaultColor]);

  return (
    <div
      className={`w-full flex flex-col h-16 items-center bg-white shadow ${
        noSticky ? "" : "fixed"
      } top-0 left-0 right-0 z-50 transition text-${defaultColor}`}
      ref={navbarRef}
    >
      <div className="px-10 sm:px-28 w-full flex flex-row h-16 items-center justify-between justify-between transition bg-white xl:bg-transparent z-50">
        <div
          className="left-component flex flex-row items-center flex-grow cursor-pointer"
          onClick={() => (window.location.href = "/")}
        >
          <div className="font-black flex color text-2xl">
            <img alt="" src={logo} className="h-10" />
          </div>
        </div>
        <div className="mr-24 center-component flex-grow font-medium z-20">
          <ul className="flex-row gap-10 justify-center hidden xl:flex">
            <li>
              <span className="nav-link" onClick={() => scrollToElementById("")}>
                Home
              </span>
            </li>
            <li>
              <span className="nav-link" onClick={() => scrollToElementById("services")}>
                Services
              </span>
            </li>

            <li>
              <span className="nav-link" onClick={() => scrollToElementById("reviews")}>
                Reviews
              </span>
            </li>
            <li>
              <span className="nav-link" onClick={() => scrollToElementById("contact")}>
                Contact
              </span>
            </li>
          </ul>
        </div>
        <div className="right-component flex-grow relative">
          <div className="xl:hidden flex flex-col">
            <div className="self-end w-fit flex">
              {!menuIsOpen && <MenuIcon className="self-end cursor-pointer" onClick={() => setMenuOpen(!menuIsOpen)} />}
              {menuIsOpen && <CloseIcon className="self-end cursor-pointer" onClick={() => setMenuOpen(!menuIsOpen)} />}
            </div>
          </div>
        </div>
      </div>
      <ul
        className={
          "flex flex-col justify-center bg-white absolute top-16 w-full shadow-md transition xl:hidden duration-300 transform z-40 font-medium " +
          (menuIsOpen ? "" : "-translate-y-full")
        }
      >
        <li className="px-10 py-4 hover:bg-gray-200">
          <span className="nav-link" onClick={() => scrollToElementById("")}>
            Home
          </span>
        </li>
        <li className="px-10 py-4 hover:bg-gray-200">
          <span className="nav-link" onClick={() => scrollToElementById("services")}>
            Services
          </span>
        </li>
        <li className="px-10 py-4 hover:bg-gray-200">
          <span className="nav-link" onClick={() => scrollToElementById("reviews")}>
            Reviews
          </span>
        </li>
        <li className="px-10 py-4 hover:bg-gray-200">
          <span className="nav-link" onClick={() => scrollToElementById("contact")}>
            Contact
          </span>
        </li>
      </ul>
    </div>
  );
};

export default Navbar;
