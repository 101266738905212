import React, { useState } from "react";

interface ButtonProps {
  backgroundColor?: string;
  color?: string;
  hoverBackgroudColor?: string;
  hoverColor?: string;
  noHover?: boolean;
  onClick: (e: any) => void;
  className?: string;
  children?: React.ReactNode;
}

const Button: React.FC<ButtonProps> = ({
  children,
  backgroundColor = "#2ed47c",
  color = "#fff",
  hoverBackgroudColor = "#fc7350",
  hoverColor = "#fff",
  onClick,
  noHover = false,
  className = "",
}) => {
  const [styles, setStyles] = useState<React.CSSProperties>({
    backgroundColor,
    color,
  });

  return (
    <button
      style={styles}
      onClick={onClick}
      onMouseEnter={() => !noHover && setStyles({ backgroundColor: hoverBackgroudColor, color: hoverColor })}
      onMouseLeave={() => !noHover && setStyles({ backgroundColor, color })}
      className={
        "px-8 py-4 uppercase text-sm rounded-full font-medium button transition duration-300 hover:shadow-lg " +
        className
      }
    >
      {children}
    </button>
  );
};

export default Button;
